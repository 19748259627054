import React from 'react';
// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from 'firebase/app';

// Add the Firebase services that you want to use
// import "firebase/auth";
import 'firebase/database';

// so it's a react app with no react :-)
function App() {
  //grab a form
  const form = document.querySelector('.form-inline');
  // console.log('[firebase.js] Form', form);

  //grab an input
  const inputEmail = form.querySelector('#inputEmail');
  // console.log('[firebase.js] inputEmail', inputEmail);

  //config your firebase push
  const config = {
    apiKey: 'AIzaSyDwgZ90lzQ4cTaJ_pXsYy8viEsAuFfaAaE',
    authDomain: 'ourmasterdata.firebaseapp.com',
    databaseURL: 'https://ourmasterdata.firebaseio.com',
    projectId: 'ourmasterdata',
    storageBucket: 'ourmasterdata.appspot.com',
    messagingSenderId: '958786910869',
    appId: '1:958786910869:web:b5d3c47823637d0c89b99c'
  };

  //create a functions to push
  function firebasePush(input) {
    //prevents from breaking
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }

    //push itself
    // var mailsRef = [];
    // mailsRef =
    firebase.database().ref('signups').push().set({
      email: input.value,
      timestamp: new Date().toString()
    });
  }

  //push on form submit
  if (form) {
    form.addEventListener('submit', function (evt) {
      evt.preventDefault();
      firebasePush(inputEmail);
      console.log('At least we got here');

      //shows alert if everything went well.
      // return alert('Data Successfully Captured');
    });
  }

  return (
    <div>
      {/* // <!-- Header --> */}
      <header id="header">
        <h1>ourMasterData</h1>
        <p>
          <strong>Goal:</strong> <br />
          Make master data better. <br />
          Wanna hear more? Sign Up below...
        </p>
      </header>
    </div>
  );
}

export default App;
